<template>
  <v-autocomplete
    v-model="selectedCustomerId"
    :items="customers"
    :loading="isCustomersLoading"
    :search-input.sync="customers_search"
    :filter="filterObject"
    clearable
    hide-details
    item-text="name"
    item-value="customer.id"
    cache-items
    hide-no-data
    outlined
    label="Customer"
    placeholder="Search by customer name or phone number"
    :multiple="multiple"
    :rules="[
      v => !!v || 'Customer name is required',
    ]"
    :error="formErrors.has('customer_id')"
    :error-messages="formErrors.get('customer_id')"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Search for customer
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="data">
      <v-chip
        v-if="multiple"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar
          left
          class="primary font-weight-light white--text"
        >
          {{ data.item.name.charAt(0) }}
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
      <span v-else>{{ data.item.name }} - {{ data.item.customer ? data.item.phone_number : 'N/A' }}</span>
    </template>
    <template v-slot:item="data">
      <v-list-item-avatar class="primary font-weight-light white--text">
        {{ data.item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="data.item.name"></v-list-item-title>
        <v-list-item-subtitle v-text="data.item.customer ? data.item.phone_number : 'N/A'"></v-list-item-subtitle>
        <br />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
  props: {
    formErrors: {
      type: Object,
      required: true,
    },
    initialCustomerId: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedCustomerId: '',
    customers: [],
    isCustomersLoading: false,
    customers_search: null,
  }),
  watch: {
    customers_search(value) {
      this.getCustomers(value)
    },
    initialCustomerId(value) {
      if (value) {
        this.getCustomer(value)
      }
    },
    showDialog(value) {
      if (value && this.customers.length === 0) {
        this.getCustomers()
      }
    },
    selectedCustomerId(newValue) {
      this.$emit('update', newValue)
    },
  },
  mounted() {
    if (this.initialCustomerId) {
      this.getCustomer(this.initialCustomerId)
    }
  },
  methods: {
    filterObject(item, queryText) {
      const itemName = item.name ? item.name.toLocaleLowerCase() : ''
      const contactNumber = item.customer && item.phone_number ? item.phone_number.toLocaleLowerCase() : ''

      return itemName.includes(queryText.toLocaleLowerCase()) || contactNumber.includes(queryText.toLocaleLowerCase())
    },
    getCustomers: _.debounce(function (value) {
      this.isCustomersLoading = true
      axios
        .get(`customers?searchColumn=['name','phone_number']&search=${value}&perPage=10`)
        .then(response => {
          this.customers = response.data.data
          this.isCustomersLoading = false
        })
        .catch(error => {
          this.isCustomersLoading = false
          this.$toast.error(error.response.data.message)
        })
    }, 500),
    getCustomer(userId) {
      axios
        .get(`users/${userId}`)
        .then(response => {
          this.customers = [response.data]
          if (this.multiple) {
            this.selectedCustomerId = [response.data.customer.id]
          } else {
            this.selectedCustomerId = response.data.customer.id
          }
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
