<template>
  <div id="unresolved-payments-table">
    <v-card class="pb-1">
      <v-card-title
        v-if="showTitle"
        class="mb-0 pb-1"
      >
        Unresolved Payments
        <v-tooltip
          max-width="300"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              color="primary"
              class="ml-2"
              v-bind="attrs"
              style="cursor: pointer;"
              v-on="on"
            >
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </template>
          <span>These payments were made to an account number not associated with any customer in the system.</span>
        </v-tooltip>
      </v-card-title>
      <v-card-title>
        <v-row>
          <v-col class="col-md-3">
            <refresh-button
              :loading="isRefreshing"
              @click="refreshUnresolvedPayments()"
            />
          </v-col>
          <v-col class="col-md-6">
            <table-filter
              :table-name="'unresolved payments'"
              :default-date-picker-date="defaultDatePickerDate"
              @filter="applyFilters"
              @filterLabelChange="onFilterLabelChange"
            />
          </v-col>
          <v-col class="col-md-3 text-md-right">
            <export-button
              :title="exportTitle"
              :headers="exportHeaders"
              :data-endpoint="constructCompleteUnresolvedPaymentsUrl"
              :orientation="'landscape'"
            />
          </v-col>
        </v-row><v-row
          justify="center"
          class="mb-0"
        >
          <v-col class="col-md-6 text-center">
            <search-input
              v-model="search"
              :filters="searchFilters"
              :active-filter="activeSearchFilter"
              @filter-change="onSearchFilterChange"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-expand-transition>
        <v-row
          v-if="selected.length > 0"
          class="mb-2"
        >
          <v-col class="text-md-right">
            <v-btn
              color="error"
              class="ma-2"
              @click="showConfirmBulkDeleteDialog = true"
            >
              <v-icon
                left
                size="22"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              Delete Selected
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="unresolvedPayments"
        :loading="isLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        show-select
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip
            v-if="can('unresolved-mpesa-payment-list')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-4
                v-bind="attrs"
                text
                icon
                @click="assignPayment(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiClipboardAccount }}
                </v-icon>
              </v-btn>
            </template>
            <span>Assign</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('unresolved-mpesa-payment-delete')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-4
                v-bind="attrs"
                text
                icon
                @click="showDeleteDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <assign-payment-dialog
      :id="id"
      :show-dialog="assignPaymentDialog"
      @close="assignPaymentDialog = false"
      @paymentAssigned="getUnresolvedPayments()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deletePayment()"
    />
    <confirm-dialog
      :show-dialog="showConfirmBulkDeleteDialog"
      :message="'Are you sure you want to delete ' + selected.length + ' records?'"
      :is-agree-button-loading="isConfirmBulkDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmBulkDeleteDialog = false"
      @agree="deleteSelectedPayments()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiClipboardAccount,
  mdiDelete,
  mdiInformationOutline,
} from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import AssignPaymentDialog from '@/components/dialogs/AssignPaymentDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    SearchInput,
    TableFilter,
    ExportButton,
    RefreshButton,
    AssignPaymentDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      unresolvedPayments: [],
      isLoading: false,
      isRefreshing: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      assignPaymentDialog: false,
      id: '',
      toDelete: '',
      icons: {
        mdiClipboardAccount,
        mdiDelete,
        mdiInformationOutline,
      },
      headers: [
        { text: 'Account Number', value: 'mpesa_payment.bill_ref_number' },
        { text: 'Phone Number', value: 'mpesa_payment.msisdn' },
        { text: 'Reference', value: 'mpesa_payment.trans_id' },
        { text: 'Amount', value: 'mpesa_payment.trans_amount' },
        { text: 'Payment Date', value: 'created_at' },
        { text: 'Actions', value: 'actions' },
      ],
      search: '',
      pagination: {
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      activeSearchFilter: 'mpesa_payment.bill_ref_number',
      tableFilters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      searchFilters: [
        { text: 'Account Number', value: 'mpesa_payment.bill_ref_number' },
        { text: 'Phone Number', value: 'mpesa_payment.msisdn' },
        { text: 'Reference', value: 'mpesa_payment.trans_id' },
        { text: 'Amount', value: 'mpesa_payment.trans_amount' },
      ],
      defaultDatePickerDate: 'Last 30 days',
      searchColumn: 'mpesa_payment.bill_ref_number',
      selected: [],
      exportTitle: 'Unresolved Payments',
    }
  },
  computed: {
    refreshTable() {
      return `${this.search}`
    },
    exportHeaders() {
      return this.headers.filter(header => header.value !== 'actions')
    },
    constructCompleteUnresolvedPaymentsUrl() {
      const sortOrder = this.options.sortDesc ? 'desc' : 'asc'
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: sortOrder,
        search: this.search,
        searchColumn: this.searchColumn,
        fromDate: this.tableFilters.dates.fromDate,
        toDate: this.tableFilters.dates.toDate,
      })

      return `unresolved-payments?${params.toString()}`
    },
  },
  watch: {
    refreshTable() {
      this.getUnresolvedPayments()
    },
    options: {
      handler() {
        this.getUnresolvedPayments()
      },
      deep: true,
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getUnresolvedPayments()
      }
    },
  },
  mounted() {
    if (!this.can('unresolved-mpesa-payment-edit') && !this.can('unresolved-mpesa-payment-delete')) {
      this.removeActionsHeader()
    }
    this.options.itemsPerPage = this.itemsPerPage
    this.getUnresolvedPayments()
  },
  methods: {
    getUnresolvedPayments: _.debounce(function () {
      this.isLoading = true
      axios.get(this.constructCompleteUnresolvedPaymentsUrl)
        .then(response => {
          this.unresolvedPayments = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
          this.isRefreshing = false
        })
    }, 500),
    assignPayment(item) {
      this.id = item.id
      this.assignPaymentDialog = true
    },
    refreshUnresolvedPayments() {
      this.isRefreshing = true
      this.getUnresolvedPayments()
    },
    showDeleteDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deletePayment() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`unresolved-payments/${this.toDelete}`)
        .then(() => {
          this.$toast.success('Payment successfully')
          this.getUnresolvedPayments()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    deleteSelectedPayments() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const selectedIds = this.selected.map(payment => payment.id)

      axios
        .delete('bulk-delete/unresolved-payments', { data: { ids: selectedIds } })
        .then(() => {
          this.selected = []
          this.getUnresolvedPayments()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmBulkDeleteDialog = false
          this.isConfirmBulkDeleteDialogButtonLoading = false
        })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getUnresolvedPayments()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getUnresolvedPayments()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    onFilterLabelChange(label) {
      this.exportTitle = label
    },
    applyFilters(filters) {
      this.tableFilters = { ...this.tableFilters, ...filters }
      this.getUnresolvedPayments()
    },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
</script>
