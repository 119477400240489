<template>
  <v-tooltip top :disabled="!tooltip">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="loading"
        class="text-none"
        @click="$emit('click')"
      >
        Refresh
        <v-icon
          right
          :class="{ rotating: loading }"
        >
          {{ icons.mdiCached }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import {
  mdiCached,
} from '@mdi/js'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: 'Click to refresh',
    },
  },
  data() {
    return {
      icons: {
        mdiCached,
      },
    }
  },
}
</script>

<style>
.rotating {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
