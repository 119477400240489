<template>
  <div class="unresolved-payments">
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <unresolved-payments :show-title="false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UnresolvedPayments from '@/components/tables/UnresolvedPaymentsTable.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'

export default {
  components: {
    UnresolvedPayments,
    breadcrumb: BreadCrumb,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Unresolved Payments',
          disabled: false,
          to: { name: 'unresolved-payments' },
        },
      ],
    }
  },
}
</script>
