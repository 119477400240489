<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Assign Payment</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="AssignPayment()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-2"
                >
                  <CustomerAutocomplete
                    :form-errors="form.errors"
                    :show-dialog="showDialog"
                    @update="updateCustomerId"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import CustomerAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'

export default {
  components: {
    CustomerAutocomplete,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: new Form({
      customer_id: '',
    }),
    action: 'Assign',
  }),
  methods: {
    updateCustomerId(newCustomerId) {
      this.form.customer_id = newCustomerId
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    AssignPayment() {
      this.form.id = this.id
      this.form
        .post(`unresolved-payments/assign/${this.id}`)
        .then(() => {
          this.$toast.success('Payment assigned successfully')
          this.$emit('close')
          this.$emit('paymentAssigned')
          this.closeDialog()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
    min-height: 48px !important;
    height: unset !important;
}
</style>
